<template>
  <div class="profile">
    <div class="profile-header">
      <div class="row">
        <div class="col align-self-center">
          <p class="big text-white mb-0">
            {{ user.user_type | userTypeText }}
          </p>
        </div>
      </div>
    </div>

    <div class="profile-content">
      <div class="row">
          <!-- Profile Photo -->
          <div class="col-12 col-sm-4 col-md-3 align-self-center"
               :class="{'text-center': breakpoint === 'xs'}">
            <div class="profile-photo-container">
              <img class="profile-photo img-fluid"
                   :src="user.profile_img_url"
                   alt="profile-photo"
                   v-if="user.profile_img_url" />

              <img class="profile-photo img-fluid"
                   src="../../../assets/images/sample_user_profile.png"
                   alt="default-profile-photo"
                   v-else/>
            </div>
          </div>

          <!-- Profile Data -->
          <div class="col-12 col-sm-8 col-md-5 align-self-center wb"
               :class="{'text-center': breakpoint === 'xs'}">
            <p class="name mb-0">
              {{ user.nickname }}
            </p>

            <p class="mb-0"
               v-show="user.user_type === 3">
              <b>Name:</b> {{ user.firstname }} {{ user.lastname }}
            </p>

            <p class="mb-0" v-show="user.user_type === 1">
              <b>Grade Level:</b> {{ user.grade_level | levelAbbreviation }}
            </p>

            <p class="mb-0">
              <b>Bio:</b> {{ user.bio }}
            </p>
          </div>

          <!-- Profile School -->
          <div class="col-12 col-sm-8 col-md-4 text-center"
               :class="{
                 'offset-sm-4': breakpoint === 'sm',
                 'school-col': breakpoint !== 'xs' && breakpoint !== 'sm'
               }"
               v-show="user.user_type !== 2 && user.user_type !== 5">
            <div>
              <p class="medium mb-0"><b> {{ schoolName }} </b></p>
              <img class="school-logo"
                   :src="schoolLogo"
                   alt="school-logo" />
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
  import FilterMixin from '@/mixins/FilterMixin';
  import BreakpointMixin from '@/mixins/BreakpointMixin';

  export default {
    name   : 'PublicProfileInfo',
    mixins : [
      FilterMixin,
      BreakpointMixin,
    ],
    data() {
      return {
        user       : null,
        school     : {},
        schoolLogo : '',
      }
    },
    computed : {
      schoolName() {
        return this.school.school_name || 'No School';
      },
    },
    methods : {

      /**
       * Get User Data
       * @param userId
       */
      getUser(userId) {
        this.$http.get('/api/user', {
          params : {
            id : userId,
          },
        }).then(response => {
          this.user = response.data;
          if (this.user.user_type != 2 && this.user.user_type != 5)
            this.getSchool();
        });
      },
      
      /**
       * Get School Info
       */
      getSchool() {
        this.$http.get('api/school/with_details', {
          params : {
            id : this.user.school_id,
          },
        }).then(res => {
          this.school = res.data;
          this.schoolLogo = res.data.logo_url ||
            process.env.VUE_APP_ADMIN_BUCKET_BASE_URL + 'school/default.png';
        }).catch(() => {
          this.schoolLogo = process.env.VUE_APP_ADMIN_BUCKET_BASE_URL +
            'school/default.png';
        });
      },
    },
    mounted() {
      this.getUser(this.$route.params.userId);
    },
  }
</script>


<style lang="scss" scoped>
  @import "../../../assets/scss/components/user/public-profile/profile-info";
</style>
